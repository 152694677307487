<template>
    <!--
  Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
  Read the documentation to get started: https://tailwindui.com/documentation
-->
    <portal to="destination">
    <div class="fixed z-10 inset-0 overflow-y-auto z-50">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" style="height:100%">
            <!--
              Background overlay, show/hide based on modal state.
              Entering: "ease-out duration-300"
                From: "opacity-0"
                To: "opacity-100"
              Leaving: "ease-in duration-200"
                From: "opacity-100"
                To: "opacity-0"
            -->
            <div class="fixed inset-0 transition-opacity">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <!-- This element is to trick the browser into centering the modal contents. -->
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <!--
              Modal panel, show/hide based on modal state.
              Entering: "ease-out duration-300"
                From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                To: "opacity-100 translate-y-0 sm:scale-100"
              Leaving: "ease-in duration-200"
                From: "opacity-100 translate-y-0 sm:scale-100"
                To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            -->
            <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle min-w-90 sm:p-6 dark:bg-gray-900" style="height:90%" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <button type="button" @click="removeFromDisplay" class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150" aria-label="Close">
                        <!-- Heroicon name: x -->
                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div class="sm:flex sm:items-start flex-col h-full w-full">
                    <h3 v-if="bannerText !== null" class="text-lg leading-6 font-medium mb-2 mx-auto text-v3-gray-800 bg-v3-gray-100 dark:bg-v3-gray-800 dark:text-v3-gray-200 w-full text-center py-3 rounded">
                        {{ bannerText }}
                    </h3>
                    <h3 class="text-lg leading-6 font-medium text-v3-gray-800 mb-2 dark:text-v3-gray-200" id="modal-headline">
                        <slot id="header">View File</slot>
                    </h3>
                    <div class="mt-3 text-center sm:mt-0 sm:text-left relative h-full w-full">
                        <iframe v-if="url.includes('/api/files')"
                            :src="`data:${mimeType};base64,${file}`"
                            download="some_name.gif"
                            style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;top:0px;left:0px;right:0px;bottom:0px" height="100%" width="100%"
                        ></iframe>
                        <img v-else :src="url" style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;position:absolute;top:0px;left:0px;right:0px;bottom:0px"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </portal>
</template>

<script>
    import {mapGetters} from "vuex";
    export default {
        props: {
            url: {
                type: String
            },
            bannerText: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                file: null,
                mimeType: null
            }
        },
        created() {
            this.downloadWithVueResource();
        },
        methods: {
            downloadWithVueResource() {
                if(this.url.includes('/api/files')) {
                    this.$http({
                        method: 'get',
                        url: this.url,
                        headers: {Authorization: `Bearer ${this.accessToken}`}
                    })
                        .then(response => {
                            this.file = response.body.file;
                            this.mimeType = response.body.mime_type;
                        })
                        .catch((error) => {
                            if (error.status === 401) {
                                alert('Please login to download the file.');
                            } else {
                                if(error.status === 500){
                                    alert('There was an error whilst downloading the file.');
                                }
                            }
                        })
                }
            },
            removeFromDisplay() {
                this.$emit('removeFromDisplay');
            },
            perform() {
                this.$emit('perform');
            }
        },
        computed: {
            ...mapGetters({
                accessToken: "getAccessToken"
            })
        }
    }
</script>

<style scoped>
</style>
